<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="about" slot="component">
      <div class="about-images">
        <!-- <img
          v-if="providerUniqueId === 'net54321'"
          class="about-images-net5-logo"
          src="@/assets/icons/Net5.svg"
          alt="net5_logo"
        />
        <img
          v-else-if="providerUniqueId === 'z01x1wPo'"
          class="about-images-vlive-logo"
          src="@/assets/icons/nammaflix_logo.png"
          alt
        />-->
        <img class="about-images-noorPlay_logo" src="@/assets/icons/NoorPlayLogo.png" alt />
        <!-- <img v-else class="about-images-vlive-logo" src="@/assets/icons/log.svg" alt /> -->
        <!-- <img class="about-images-vlive-title" src="@/assets/icons/text.svg" alt /> -->
        <!-- <p class="about-images-version">V1.0.1</p> -->
      </div>
      <div class="about-description">
        <p
          class="about-description-paragraph"
        >{{ localDisplayLang === 'ara' ? appConfig.providerDetails.aboutText.ar : appConfig.providerDetails.aboutText.default }}</p>
      </div>
      <div class="about-social">
        <p class="about-social-follow">{{ $t('follow us') }}</p>
        <div class="about-social-icons">
          <a
            :href="appConfig.providerDetails.social.facebook"
            target="_blank"
            rel="noopener"
            aria-label="facebook link"
          >
            <img src="@/assets/icons/newfb.svg" alt="facebook_img" />
          </a>
          <a
            :href="appConfig.providerDetails.social.instagram"
            target="_blank"
            rel="noopener"
            aria-label="instagram link"
          >
            <img src="@/assets/icons/newinsta.svg" alt="instagram_img" />
          </a>
          <a
            :href="appConfig.providerDetails.social.twitter"
            target="_blank"
            rel="noopener"
            aria-label="twitter link"
          >
            <img src="@/assets/icons/newtwitter.svg" alt="twitter_img" />
          </a>
          <!-- <a
            :href="appConfig.providerDetails.social.pinterest"
            target="_blank"
            rel="noopener"
            aria-label="pinterest link"
          >
            <img src="@/assets/icons/newpinterest.svg" alt="pinterest_img" />
          </a>-->
          <a
            :href="appConfig.providerDetails.social.youtube"
            target="_blank"
            rel="noopener"
            aria-label="pinterest link"
          >
            <img src="@/assets/icons/youtube.svg" alt="youtube" />
          </a>
        </div>
      </div>
      <div class="about-app-share">
        <button
          class="button-primary"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          @click="shareApp"
        ><span class="btn-txt-color">{{ $t('share the app') }}</span></button>
      </div>
      <div class="about-policy-links">
        <!-- <router-link to="/TermsOfUse">
          <p @click="closeAbout">Terms Of Use</p>
        </router-link>
        <router-link to="/PrivacyPolicy">
          <p @click="closeAbout">Privacy Policy</p>
        </router-link>-->
        <router-link :to="localDisplayLang === 'ara' ? '/ar/termsOfUse' : '/en/termsofuse'">
          <p>
            {{ $t('terms of use') }}
          </p>
        </router-link>
        <router-link :to="localDisplayLang === 'ara' ? '/ar/privacypolicy' : '/en/privacypolicy'">
          <p>
            {{ $t('privacy policy') }}
          </p>
        </router-link>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";
export default {
  props: {
    closePopup: {
      type: Function
    }
  },
  data() {
    return {
      width: "50%",
      margin: "",
      radiusType: "full",
      providerUniqueId: _providerId,
      localDisplayLang: null
    };
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"])
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", data => {
      this.localDisplayLang = data;
    });
    //setting margin for userauth popup based on screen width.
    if (screen.width < 576) {
      this.margin = "45% auto";
    } else {
      this.margin = "10% auto";
    }
  },
  methods: {
    closeAbout() {
      this.closePopup();
    },
    shareApp() {
      eventBus.$emit("show-share-app-popup", true);
    }
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue")
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.about {
  border-radius: 10px;
  padding: 4rem;
  display: grid;
  justify-content: center;
  &-images {
    display: flex;
    flex-direction: column;
    align-items: center;


    &-vlive-logo {
      width: 85px;
    }
    &-net5-logo {
      width: 85px;
    }
    &-vlive-title {
      margin-top: 0.8rem;
    }

    &-noorPlay_logo {
      height: 50px;
    }
    &-version {
      margin-top: 0.8rem;
      font-family: $font-regular;
      color: $clr-popup-para;
      font-size: 0.8rem;
    }
  }
  &-description {
    &-paragraph {
      color: $clr-popup-para;
      font-family: $font-regular;
      text-align: center;
      font-size: 1.2rem;
      letter-spacing: 0;
      line-height: 2.4rem;
      padding: 2rem;
      line-height: 25px;
    }
  }
  &-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-follow {
      color: $clr-popup-para;
      padding-bottom: 1rem;
      font-family: $font-regular;
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.1rem;
    }
    &-icons {
      img {
        height: 1.4rem;
        padding: 0 0.6rem;
        cursor: pointer;
        opacity: 0.3;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &-app-share {
    width: 30%;
    margin: 4% auto 0%;
  }
  &-policy-links {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    p {
      padding: 0 0.6rem;
      color: white;
      opacity: 0.5;
      font-size: 0.6rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .about {
    padding: 2rem;
    &-images {
      &-vlive {
        &-logo {
          width: 80px;
        }
      }
    }
    &-description {
      &-paragraph {
        font-size: 1rem;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .about {
    padding: 4rem;
    &-images {
      &-vlive {
        &-logo {
          width: 120px;
        }
      }
    }
    &-description {
      &-paragraph {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
